import { createStore } from 'vuex'

//modules imports
import auth from "@/store/modules/auth";
import shared from "@/store/modules/shared";
import customers from "@/store/modules/customers";
import exploreHub from "@/store/modules/explore_hub";
import investmentHub from "@/store/modules/investment_hub";
import chat from "@/store/modules/chat";
import financeInstitution from "@/store/modules/finance_institutions";
import transactionCategories from "@/store/modules/transactions_hub";
import transactionSubCategories from "@/store/modules/transactions_hub";
import transactionSubCategoriesNarrations from "@/store/modules/transactions_hub";
import faqs from "@/store/modules/faqs_hub";
import faqsTopic from "@/store/modules/faqs_hub";
import influencers from "@/store/modules/influencers";
import surveys from "@/store/modules/surveys";
import avgmonthlybudgets from "@/store/modules/dashboard";


export default createStore({
  state: {},
  mutations: {},
  getters: {},
  modules: {
    auth,
    shared,
    customers,
    exploreHub,
    investmentHub,
    chat,
    financeInstitution,
    transactionCategories,
    transactionSubCategories,
    transactionSubCategoriesNarrations,
    faqs,
    faqsTopic,
    influencers,
    surveys,
    avgmonthlybudgets,
  }
})
