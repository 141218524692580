//determine the base URL base on the env
/*
* POSSIBLE BASE URLS (for easy copy-pasting)
* https://zola-api.codebreeze.co.ke
* https://staging.myzola.io
* http://127.0.0.1:8000
*
* */
//const baseUrl = 'http://127.0.0.1:8001';
const baseUrl = process.env.NODE_ENV === "development" ? process.env.VUE_APP_API_BASE_URL : "https://zola-api.codebreeze.co.ke";
const apiBaseUrl = `${baseUrl}/api/admin`;
//define the api routes
const apiRoutes = {
    SANCTUM_CSRF_TOKEN: `${baseUrl}/sanctum/csrf-cookie`,
    LOGIN: `${apiBaseUrl}/auth/login`,
    
    /* -----------------------------
     * CUSTOMERS
     * -----------------------------
     * */
    FETCH_CUSTOMERS: `${apiBaseUrl}/customers/fetch`,
    FETCH_CUSTOMERS_JSON: `${apiBaseUrl}/customers/fetch-json`,
    DEACTIVATE_CUSTOMER_ACCOUNT: `${apiBaseUrl}/customers/deactivate-account`,
    REACTIVATE_CUSTOMER_ACCOUNT: `${apiBaseUrl}/customers/reactivate-account`,
    EXPORT_CUSTOMERS: `${apiBaseUrl}/customers/export-customers`,
    UNSUBSCRIBE_CUSTOMER_NEWSLETTER:`${apiBaseUrl}/customers/unsubscribe-newsletter`,
    SUBSCRIBE_CUSTOMER_NEWSLETTER:`${apiBaseUrl}/customers/subscribe-newsletter`,

    /* -----------------------------
     * EXPLORE HUB COMPANIES
     * -----------------------------
     * */

    GET_EXPLORE_LISTED_COMPANIES: `${apiBaseUrl}/explore-hub/get-listed-companies`,
    ADD_EXPLORE_LISTED_COMPANY: `${apiBaseUrl}/explore-hub/add-listed-company`,
    EDIT_EXPLORE_LISTED_COMPANY: `${apiBaseUrl}/explore-hub/edit-listed-company`,
    APPROVE_EXPLORE_LISTED_COMPANY: `${apiBaseUrl}/explore-hub/approve-listed-company`,
    REJECT_EXPLORE_LISTED_COMPANY: `${apiBaseUrl}/explore-hub/reject-listed-company`,
    ARCHIVE_EXPLORE_LISTED_COMPANY: `${apiBaseUrl}/explore-hub/archive-listed-company`,
    DELETE_EXPLORE_LISTED_COMPANY: `${apiBaseUrl}/explore-hub/delete-listed-company`,
    EXPLORE_HUB_COMPANY_REVIEWS: `${apiBaseUrl}/explore-hub/company-reviews`,
    EXPLORE_HUB_COMPANY_REVIEWS_SUMMARY: `${apiBaseUrl}/explore-hub/company-reviews-summary`,
    EXPLORE_HUB_ARCHIVED_COMPANIES: `${apiBaseUrl}/explore-hub/get-archived-companies`,
    EXPLORE_HUB_RESTORE_ARCHIVED_COMPANY: `${apiBaseUrl}/explore-hub/restore-archived-company`,
    EXPLORE_HUB_DELETE_ARCHIVED_COMPANY: `${apiBaseUrl}/explore-hub/delete-archived-company`,

    //new explorehub routes
    ADD_EXPLORE_COMPANY: `${apiBaseUrl}/explore-hub/add-company`,
    GET_BUSINESS_INDUSTRIES: `${apiBaseUrl}/explore-hub/get-explore-business-industries`,
    ADD_EXPLORE_COMPANY_DIRECTORS_DOCUMENTS: `${apiBaseUrl}/explore-hub/add-partner-business-directors-documents`,
    ADD_EXPLORE_COMPANY_BANKS: `${apiBaseUrl}/explore-hub/add-partner-business-banks`,
    GET_BUSINESS_BANKS: `${apiBaseUrl}/finance-institutions/get-banks`,
    ADD_EXPLORE_COMPANY_SOCIALS: `${apiBaseUrl}/explore-hub/add-partner-business-socials`,
    UPDATE_COMPANY_DETAILS: `${apiBaseUrl}/explore-hub/update-company`,
    UPDATE_EXPLORE_COMPANY_DIRECTORS_DOCUMENTS: `${apiBaseUrl}/explore-hub/update-company-directors-documents`,
    UPDATE_EXPLORE_COMPANY_BANKS: `${apiBaseUrl}/explore-hub/update-company-bank`,
    UPDATE_EXPLORE_COMPANY_SOCIALS: `${apiBaseUrl}/explore-hub/update-company-socials`,
    EXPLORE_HUB_GET_EXPLORE_COMPANIES: `${apiBaseUrl}/explore-hub/get-explore-companies`,
    ADMIN_STORE_EXPLORE_LISTING: `${apiBaseUrl}/explore-hub/store-explore-listing`,
    ADMIN_UPDATE_EXPLORE_LISTING: `${apiBaseUrl}/explore-hub/update-listing`,
    EXPLORE_HUB_GET_EXPLORE_LISTING: `${apiBaseUrl}/explore-hub/get-listing`,
    EXPLORE_HUB_GET_ALL_COMPLAINTS: `${apiBaseUrl}/explore-hub/get-all-complaints`,


    /* -----------------------------
     * ADMIN DASHBOARD ROUTES
     * -----------------------------
     * */
    ADMIN_FETCH_CUSTOMER_COUNT: `${apiBaseUrl}/dashboard/fetch-customer-count`,
    ADMIN_FETCH_PARTNER_COUNT: `${apiBaseUrl}/dashboard/fetch-partner-count`,
    ADMIN_FETCH_INFLUENCER_COUNT: `${apiBaseUrl}/dashboard/fetch-influencers-count`,
    ADMIN_FETCH_CUSTOMER_SIGNUPS: `${apiBaseUrl}/dashboard/fetch-customer-signups`,
    ADMIN_FETCH_CUSTOMER_DEMOGRAPHICS: `${apiBaseUrl}/dashboard/fetch-customer-demographics`,
    ADMIN_FETCH_RECENT_SIGNUPS: `${apiBaseUrl}/dashboard/fetch-recent-signups`,
    ADMIN_FETCH_DEACTIVATED_CUSTOMERS: `${apiBaseUrl}/dashboard/fetch-deactivated-customers`,
    ADMIN_FETCH_ACTIVE_CUSTOMERS: `${apiBaseUrl}/dashboard/fetch-active-customers`,
    ADMIN_FETCH_RECENT_COMPANIES: `${apiBaseUrl}/dashboard/get-recent-companies`,
    ADMIN_FETCH_MONTHLY_FINANCIAL_TRACKING: `${apiBaseUrl}/dashboard/get-monthly-financial-tracking`,


    //financial statistics overview
    ADMIN_FETCH_INCOME_STATISTICS: `${apiBaseUrl}/dashboard/get-income-statistics`,
    ADMIN_FETCH_EXPENSES_STATISTICS: `${apiBaseUrl}/dashboard/get-expenses-statistics`,
    ADMIN_FETCH_SAVINGS_STATISTICS: `${apiBaseUrl}/dashboard/get-savings-statistics`,
    ADMIN_FETCH_LOANS_STATISTICS: `${apiBaseUrl}/dashboard/get-loans-statistics`,
    ADMIN_FETCH_INVESTMENTS_STATISTICS: `${apiBaseUrl}/dashboard/get-investments-statistics`,
    ADMIN_FETCH_ASSETS_STATISTICS: `${apiBaseUrl}/dashboard/get-assets-statistics`,
    ADMIN_FETCH_MONTHLY_BUDGET_STATISTICS: `${apiBaseUrl}/dashboard/get-monthly-budget-statistics`,
    ADMIN_FETCH_PROJECT_BUDGET_STATISTICS: `${apiBaseUrl}/dashboard/get-project-budget-statistics`,

    //monthly budget breakdown
    ADMIN_FETCH_MONTHLY_BUDGET_BY_CATEGORY: `${apiBaseUrl}/dashboard/get-monthly-budget-by-category`,
    ADMIN_FETCH_TOTAL_MONTHLY_BUDGET_BY_CATEGORY: `${apiBaseUrl}/dashboard/get-total-monthly-budget-by-category`,


    /* -----------------------------
     * EXPLORE HUB LISTING CATEGORIES
     * -----------------------------
     * */
    EXPLORE_HUB_ADD_LISTING_CATEGORIES: `${apiBaseUrl}/explore-hub/add-listing-categories`,
    EXPLORE_HUB_FETCH_LISTING_CATEGORIES: `${apiBaseUrl}/explore-hub/get-listing-categories`,
    EXPLORE_HUB_EDIT_LISTING_CATEGORY: `${apiBaseUrl}/explore-hub/edit-listing-category`,
    EXPLORE_HUB_DELETE_LISTING_CATEGORIES: `${apiBaseUrl}/explore-hub/delete-listing-categories`,

    /* -----------------------------
     * EXPLORE HUB LISTINGS
     * -----------------------------
     * */
    EXPLORE_HUB_FETCH_LISTINGS: `${apiBaseUrl}/explore-hub/get-listings`,
    EXPLORE_HUB_ADD_LISTING: `${apiBaseUrl}/explore-hub/add-listing`,
    EXPLORE_HUB_EDIT_LISTING: `${apiBaseUrl}/explore-hub/edit-listing`,
    EXPLORE_HUB_ARCHIVE_LISTING: `${apiBaseUrl}/explore-hub/archive-listing`,
    EXPLORE_HUB_DELETE_LISTING: `${apiBaseUrl}/explore-hub/delete-listing`,
    EXPLORE_HUB_ADD_LISTING_PAYMENTS: `${apiBaseUrl}/explore-hub/add-listing-payments`,
    EXPLORE_HUB_ADD_LISTING_MEDIA: `${apiBaseUrl}/explore-hub/add-listing-media`,
    EXPLORE_HUB_REPORTED_LISTINGS: `${apiBaseUrl}/explore-hub/reported-listings`,
    EXPLORE_HUB_LISTING_REPORTS: `${apiBaseUrl}/explore-hub/listing-reports`,
    EXPLORE_HUB_ARCHIVED_LISTINGS: `${apiBaseUrl}/explore-hub/get-archived-listings`,
    EXPLORE_HUB_RESTORE_ARCHIVED_LISTING: `${apiBaseUrl}/explore-hub/restore-archived-listing`,
    EXPLORE_HUB_DELETE_ARCHIVED_LISTING: `${apiBaseUrl}/explore-hub/delete-archived-listing`,
    EXPLORE_HUB_SUBMIT_LISTING_FOR_APPROVAL: `${apiBaseUrl}/explore-hub/submit-listing-for-approval`,
    EXPLORE_HUB_APPROVE_LISTING: `${apiBaseUrl}/explore-hub/approve-listing`,
    EXPLORE_HUB_REJECT_LISTING: `${apiBaseUrl}/explore-hub/reject-listing`,
    EXPLORE_HUB_PENDING_REVIEWS: `${apiBaseUrl}/explore-hub/pending-reviews`,
    EXPLORE_HUB_APPROVE_PENDING_REVIEW: `${apiBaseUrl}/explore-hub/approve-pending-review`,
    EXPLORE_HUB_REJECT_PENDING_REVIEW: `${apiBaseUrl}/explore-hub/reject-pending-review`,
    EXPLORE_HUB_LISTINGS_WITH_APPROVED_REVIEWS: `${apiBaseUrl}/explore-hub/listings-with-approved-reviews`,
    EXPLORE_HUB_LISTING_REVIEWS: `${apiBaseUrl}/explore-hub/listing-reviews`,
    EXPLORE_HUB_LISTING_REVIEWS_SUMMARY: `${apiBaseUrl}/explore-hub/listing-reviews-summary`,
    


    /* -----------------------------
     * INVESTMENT HUB COMPANIES
     * -----------------------------
     * */
    GET_INVESTMENT_HUB_LISTED_COMPANIES: `${apiBaseUrl}/investment-hub/get-listed-companies`,
    ADD_INVESTMENT_HUB_LISTED_COMPANY: `${apiBaseUrl}/investment-hub/add-listed-company`,
    EDIT_INVESTMENT_HUB_LISTED_COMPANY: `${apiBaseUrl}/investment-hub/edit-listed-company`,
    APPROVE_INVESTMENT_HUB_LISTED_COMPANY: `${apiBaseUrl}/investment-hub/approve-listed-company`,
    REJECT_INVESTMENT_HUB_LISTED_COMPANY: `${apiBaseUrl}/investment-hub/reject-listed-company`,
    ARCHIVE_INVESTMENT_HUB_LISTED_COMPANY: `${apiBaseUrl}/investment-hub/archive-listed-company`,
    DELETE_INVESTMENT_HUB_LISTED_COMPANY: `${apiBaseUrl}/investment-hub/delete-listed-company`,
    INVESTMENT_HUB_COMPANY_REVIEWS: `${apiBaseUrl}/investment-hub/company-reviews`,
    INVESTMENT_HUB_COMPANY_REVIEWS_SUMMARY: `${apiBaseUrl}/investment-hub/company-reviews-summary`,
    INVESTMENT_HUB_ARCHIVED_COMPANIES: `${apiBaseUrl}/investment-hub/get-archived-companies`,
    INVESTMENT_HUB_RESTORE_ARCHIVED_COMPANY: `${apiBaseUrl}/investment-hub/restore-archived-company`,
    INVESTMENT_HUB_DELETE_ARCHIVED_COMPANY: `${apiBaseUrl}/investment-hub/delete-archived-company`,

    /* -----------------------------
     * INVESTMENT HUB LISTING CATEGORIES
     * -----------------------------
     * */
    INVESTMENT_HUB_ADD_LISTING_CATEGORIES: `${apiBaseUrl}/investment-hub/add-listing-categories`,
    INVESTMENT_HUB_FETCH_LISTING_CATEGORIES: `${apiBaseUrl}/investment-hub/get-listing-categories`,
    INVESTMENT_HUB_EDIT_LISTING_CATEGORY: `${apiBaseUrl}/investment-hub/edit-listing-category`,
    INVESTMENT_HUB_DELETE_LISTING_CATEGORIES: `${apiBaseUrl}/investment-hub/delete-listing-categories`,

    /* -----------------------------
     * INVESTMENT HUB LISTINGS
     * -----------------------------
     * */
    INVESTMENT_HUB_FETCH_LISTINGS: `${apiBaseUrl}/investment-hub/get-listings`,
    INVESTMENT_HUB_ADD_LISTING: `${apiBaseUrl}/investment-hub/add-listing`,
    INVESTMENT_HUB_EDIT_LISTING: `${apiBaseUrl}/investment-hub/edit-listing`,
    INVESTMENT_HUB_ARCHIVE_LISTING: `${apiBaseUrl}/investment-hub/archive-listing`,
    INVESTMENT_HUB_DELETE_LISTING: `${apiBaseUrl}/investment-hub/delete-listing`,
    INVESTMENT_HUB_ADD_LISTING_PAYMENTS: `${apiBaseUrl}/investment-hub/add-listing-payments`,
    INVESTMENT_HUB_ADD_LISTING_MEDIA: `${apiBaseUrl}/investment-hub/add-listing-media`,
    INVESTMENT_HUB_REPORTED_LISTINGS: `${apiBaseUrl}/investment-hub/reported-listings`,
    INVESTMENT_HUB_LISTING_REPORTS: `${apiBaseUrl}/investment-hub/listing-reports`,
    INVESTMENT_HUB_ARCHIVED_LISTINGS: `${apiBaseUrl}/investment-hub/get-archived-listings`,
    INVESTMENT_HUB_RESTORE_ARCHIVED_LISTING: `${apiBaseUrl}/investment-hub/restore-archived-listing`,
    INVESTMENT_HUB_DELETE_ARCHIVED_LISTING: `${apiBaseUrl}/investment-hub/delete-archived-listing`,
    INVESTMENT_HUB_SUBMIT_LISTING_FOR_APPROVAL: `${apiBaseUrl}/investment-hub/submit-listing-for-approval`,
    INVESTMENT_HUB_APPROVE_LISTING: `${apiBaseUrl}/investment-hub/approve-listing`,
    INVESTMENT_HUB_REJECT_LISTING: `${apiBaseUrl}/investment-hub/reject-listing`,
    INVESTMENT_HUB_PENDING_REVIEWS: `${apiBaseUrl}/investment-hub/pending-reviews`,
    INVESTMENT_HUB_APPROVE_PENDING_REVIEW: `${apiBaseUrl}/investment-hub/approve-pending-review`,
    INVESTMENT_HUB_REJECT_PENDING_REVIEW: `${apiBaseUrl}/investment-hub/reject-pending-review`,
    INVESTMENT_HUB_LISTINGS_WITH_APPROVED_REVIEWS: `${apiBaseUrl}/investment-hub/listings-with-approved-reviews`,
    INVESTMENT_HUB_LISTING_REVIEWS: `${apiBaseUrl}/investment-hub/listing-reviews`,
    INVESTMENT_HUB_LISTING_REVIEWS_SUMMARY: `${apiBaseUrl}/investment-hub/listing-reviews-summary`,

    /* -----------------------------
     * CUSTOMER CARE
     * -----------------------------
     * */
    GET_CUSTOMER_CONVERSATIONS: `${apiBaseUrl}/customer-care/get-admin-chat-conversations`,
    GET_CONVERSATION_MESSAGES: `${apiBaseUrl}/customer-care/get-conversation-messages`,
    SEND_MESSAGE_TO_CUSTOMER: `${apiBaseUrl}/customer-care/send-message-to-customer`,
   

    /* -----------------------------
     * FINANCE INSTITUTIONS
     * -----------------------------
     * */
    FETCH_FINANCE_INSTITUTIONS: `${apiBaseUrl}/finance-institutions/fetch`,
    ADD_FINANCE_INSTITUTION: `${apiBaseUrl}/finance-institutions/store`,
    UPDATE_FINANCE_INSTITUTION: `${apiBaseUrl}/finance-institutions/update`,
/* -----------------------------
     * TRANSACTIONS HUB
     * -----------------------------
     * */
    FETCH_TRANSACTION_CATEGORIES: `${apiBaseUrl}/transactions-categories/fetch`,
    ADD_TRANSACTION_CATEGORY: `${apiBaseUrl}/transactions-categories/store`,
    UPDATE_TRANSACTION_CATEGORY: `${apiBaseUrl}/transactions-categories/update`,
    APPROVE_TRANSACTION_CATEGORY: `${apiBaseUrl}/transactions-categories/approve`,
    DEACTIVATE_TRANSACTION_CATEGORY: `${apiBaseUrl}/transactions-categories/deactivate`,
    EXPORT_CATEGORIES: `${apiBaseUrl}/transactions-categories/export-categories`,


    FETCH_TRANSACTION_SUB_CATEGORIES: `${apiBaseUrl}/transactions-sub-categories/fetch-sub-categories`,
    ADD_TRANSACTION_SUB_CATEGORY: `${apiBaseUrl}/transactions-sub-categories/store-sub-categories`,
    UPDATE_TRANSACTION_SUB_CATEGORY: `${apiBaseUrl}/transactions-sub-categories/update-sub-categories`,
    APPROVE_TRANSACTION_SUB_CATEGORY: `${apiBaseUrl}/transactions-sub-categories/approve-sub-category`,
    DEACTIVATE_TRANSACTION_SUB_CATEGORY: `${apiBaseUrl}/transactions-sub-categories/deactivate-sub-category`,
    EXPORT_SUB_CATEGORIES: `${apiBaseUrl}/transactions-sub-categories/export-sub-categories`,

    FETCH_TRANSACTION_SUB_CATEGORIES_NARRATIONS: `${apiBaseUrl}/transactions-sub-categories-narrations/fetch-sub-categories-narrations`,
    ADD_TRANSACTION_SUB_CATEGORY_NARRATIONS: `${apiBaseUrl}/transactions-sub-categories-narrations/store-sub-categories-narrations`,
    UPDATE_TRANSACTION_CATEGORY_NARRATIONS: `${apiBaseUrl}/transactions-sub-categories-narrations/update-sub-categories-narrations`,
    DELETE_TRANSACTION_CATEGORY_NARRATIONS: `${apiBaseUrl}/transactions-sub-categories-narrations/delete-sub-categories-narration`,
    EXPORT_SUB_CATEGORIES_NARRATIONS: `${apiBaseUrl}/transactions-sub-categories-narrations/export-sub-categories-narrations`,

    /* -----------------------------
     * FAQs
     * -----------------------------
     * */
    FETCH_FAQS: `${apiBaseUrl}/faqs/fetch-faq`,
    ADD_FAQ: `${apiBaseUrl}/faqs/store-faq`,
    UPDATE_FAQ: `${apiBaseUrl}/faqs/update-faq`,
    DELETE_FAQ: `${apiBaseUrl}/faqs/delete-faq`,

    FETCH_FAQS_TOPICS: `${apiBaseUrl}/faqs/fetch-faq-topic`,
    ADD_FAQ_TOPIC: `${apiBaseUrl}/faqs/store-faq-topic`,
    UPDATE_FAQ_TOPIC: `${apiBaseUrl}/faqs/update-faq-topic`,
    DELETE_FAQ_TOPIC: `${apiBaseUrl}/faqs/delete-faq-topic`,

    /* -----------------------------
        * Influencers
        * -----------------------------
        * */
    FETCH_INFLUENCERS: `${apiBaseUrl}/influencers/fetch-influencers`,
    ADD_INFLUENCER: `${apiBaseUrl}/influencers/store-influencers`,
    UPDATE_INFLUENCER: `${apiBaseUrl}/influencers/update-influencers`,
    DELETE_INFLUENCER: `${apiBaseUrl}/influencers/delete-influencer`,

        /* -----------------------------
        * Surveys
        * -----------------------------
        * */
    FETCH_REGISTRATION_SURVEYS: `${apiBaseUrl}/surveys/fetch-surveys`,
    //ADD_REGISTRATION_SURVEY: `${apiBaseUrl}/surveys/registration/add`,
    UPDATE_REGISTRATION_SURVEY: `${apiBaseUrl}/surveys/update-survey`,
    DELETE_REGISTRATION_SURVEY: `${apiBaseUrl}/surveys/delete-survey`,


    /* -----------------------------
     * NOTIFICATION CENTER
     * -----------------------------
     * */
    FETCH_WALLET_BALANCE: `${apiBaseUrl}/notification-center/fetch-wallet-balance`,
    FETCH_MAIL_BALANCE: `${apiBaseUrl}/notification-center/fetch-mailtrap-usage-information`,
    BROADCAST_NOTIFICATION_TO_CUSTOMERS: `${apiBaseUrl}/notification-center/broadcast-notification-to-customers`,
    FETCH_SCHEDULED_NOTIFICATIONS: `${apiBaseUrl}/notification-center/fetch-scheduled-notifications`,
    CANCEL_SCHEDULED_NOTIFICATION: `${apiBaseUrl}/notification-center/cancel-scheduled-notification`,
    UPDATE_SCHEDULED_NOTIFICATION: `${apiBaseUrl}/notification-center/update-scheduled-notification`,

    /* ---------------------------------------
    * ACCESS CONTROL ROUTES
    * ---------------------------------------
    * */
    //Roles
    LIST_ROLES: `${apiBaseUrl}/roles/list`,
    ADD_NEW_ROLES: `${apiBaseUrl}/roles/add-roles`,
    EDIT_ROLE: `${apiBaseUrl}/roles/edit-role`,
    DELETE_ROLES: `${apiBaseUrl}/roles/delete-roles`,

    //Permissions
    GET_ROLE_PERMISSIONS: `${apiBaseUrl}/permissions/get-role-permissions`,
    SAVE_ROLE_PERMISSIONS: `${apiBaseUrl}/permissions/save-role-permissions`,
    SIGNEDIN_USER_PERMISSIONS: `${apiBaseUrl}/permissions/auth-user`,

    //Users
    LIST_USERS: `${apiBaseUrl}/users/list`,
    INVITE_USERS: `${apiBaseUrl}/users/invite-users`,
    VALIDATE_INVITE_TOKEN: `${apiBaseUrl}/users/validate-invite-token`,
    ACCEPT_INVITE: `${apiBaseUrl}/users/accept-invite`,
    SET_USER_ROLES: `${apiBaseUrl}/users/set-roles`,
    DELETE_USER: `${apiBaseUrl}/users/delete-user`,
};

export { baseUrl, apiBaseUrl, apiRoutes };
