const state = {
    categories: [],
    subCategories:[],
    subCategoriesNarrations:[],
    isFetchingCategories: false,
    isFetchingSubCategories:false,
    isFetchingSubCategoriesNarrations:false,
}
const mutations = {
    STORE_TRANSACTIONS_CATEGORIES: (state, data)=> state.categories = data,
    SET_IS_FETCHING_CATEGORIES: (state, value)=> state.isFetchingCategories = value,

    STORE_TRANSACTIONS_SUB_CATEGORIES: (state, data)=> state.subCategories = data,
    SET_IS_FETCHING_SUB_CATEGORIES: (state, value)=> state.isFetchingSubCategories = value,

    STORE_TRANSACTIONS_SUB_CATEGORIES_NARRATIONS: (state, data)=> state.subCategoriesNarrations = data,
    SET_IS_FETCHING_SUB_CATEGORIES_NARRATIONS: (state, value)=> state.isFetchingSubCategoriesNarrations = value,
}

export default {
    namespaced: true,
    state,
    mutations,
};
