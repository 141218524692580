import api from "@/services/api";
import { apiRoutes } from "@/services/apiRoutes";

export default {
    namespaced: true,

    state: {
        companies: [],
        isFetchingCompanies: false,
        editCompany: null,
        archivedCompanies: [],
        isFetchingArchivedCompanies: false,
        listingCategories: [],
        isFetchingCategories: false,
        activeListings: [],
        activeListingsFilters: {},
        isActiveListingsLoading: false,
        editListing: null,
        viewedListing: null,
        archivedListings: [],
        isArchivedListingsLoading: false,
        viewedArchivedListing: null,
        isComplaintLoading: false,
        approvedReviewsListings: [],
        isApprovedReviewsListingsLoading: false,
        complaints: [],
        viewedComplaint: null,
    },

    mutations: {
        STORE_EXPLORE_LISTED_COMPANIES(state, companies) {
            state.companies = companies;
        },
        SET_IS_FETCHING_COMPANIES(state, isLoading) {
            state.isFetchingCompanies = isLoading;
        },
        SET_EDIT_COMPANY(state, company) {
            state.editCompany = company;
        },
        SET_IS_FETCHING_ARCHIVED_COMPANIES(state, value) {
            state.isFetchingArchivedCompanies = value;
        },
        STORE_ARCHIVED_COMPANIES(state, data) {
            state.archivedCompanies = data;
        },
        STORE_EXPLORE_LISTING_CATEGORIES(state, data) {
            state.listingCategories = data;
        },
        SET_IS_FETCHING_CATEGORIES(state, value) {
            state.isFetchingCategories = value;
        },
        STORE_ACTIVE_LISTINGS(state, data) {
            state.activeListings = data;
        },
        STORE_ACTIVE_LISTINGS_FILTERS(state, data) {
            state.activeListingsFilters = data;
        },
        SET_IS_ACTIVE_LISTINGS_LOADING(state, value) {
            state.isActiveListingsLoading = value;
        },
        STORE_EDIT_LISTING(state, data) {
            state.editListing = data;
        },
        STORE_VIEWED_LISTING(state, data) {
            state.viewedListing = data;
        },
        STORE_ARCHIVED_LISTINGS(state, data) {
            state.archivedListings = data;
        },
        SET_IS_ARCHIVED_LISTINGS_LOADING(state, value) {
            state.isArchivedListingsLoading = value;
        },
        STORE_VIEWED_ARCHIVED_LISTING(state, data) {
            state.viewedArchivedListing = data;
        },
        STORE_APPROVED_REVIEWS_LISTINGS(state, data) {
            state.approvedReviewsListings = data;
        },
        SET_IS_APPROVED_REVIEWS_LISTINGS_LOADING(state, value) {
            state.isApprovedReviewsListingsLoading = value;
        },
        SET_IS_COMPLAINTS_LOADING(state, value) {
            state.isComplaintLoading = value;
        },
        STORE_VIEWED_COMPLAINT(state, data) {
            state.viewedComplaint = data;
        }
    },

    actions: {
        fetchCompanyById({ commit, state }, id) {
            if (!id) {
                console.error('No ID provided for fetchCompanyById');
                return Promise.reject(new Error('ID is required'));
            }
            const existingCompany = state.companies.find(c => c.id == id);
            if (existingCompany) {
                commit('SET_EDIT_COMPANY', JSON.parse(JSON.stringify(existingCompany)));
                return Promise.resolve(existingCompany);
            }
            commit('SET_IS_FETCHING_COMPANIES', true);
            return api.get(`${apiRoutes.GET_EXPLORE_LISTED_COMPANIES}/${id}`)
                .then(response => {
                    if (response.data) {
                        commit('SET_EDIT_COMPANY', response.data);
                        return response.data;
                    }
                })
                .catch(error => {
                    console.error('Failed to fetch company:', error);
                    throw error;
                })
                .finally(() => {
                    commit('SET_IS_FETCHING_COMPANIES', false);
                });
        }
    }
};