const state = {
    influencers: [],
    isFetchingInfluencers:false,
   
}
const mutations = {
    STORE_INFLUENCERS: (state, data)=> state.influencers = data,
    SET_IS_FETCHING_INFLUENCERS: (state, value)=> state.isFetchingInfluencers = value,

}

export default {
    namespaced: true,
    state,
    mutations,
};
