const state = {
    faqs: [],
    faqsTopic: {
        current_page: 1,
        per_page: 20,
        total: 0,
        data: []
      },
    isFetchingFaqs:false,
    isFetchingFaqsTopic:false,
}
const mutations = {
    STORE_FAQS: (state, data)=> state.faqs = data,
    SET_IS_FETCHING_FAQS: (state, value)=> state.isFetchingFaqs = value,

    STORE_FAQS_TOPIC: (state, data)=> state.faqsTopic = data,
    SET_IS_FETCHING_FAQS_TOPICS: (state, value)=> state.isFetchingFaqsTopic = value,
}

export default {
    namespaced: true,
    state,
    mutations,
};
