const state = {
    avgmonthlybudgets: [],
    isFetchingAvgMonthlyBudgets:false,
    totalmonthlybudgets: [],
    isFetchingTotalMonthlyBudgets:false,

}
const mutations = {
    STORE_AVGMONTHLYAMOUNT: (state, data)=> state.avgmonthlybudgets = data,
    SET_IS_FETCHING_AVGMONTHLYANOUNT: (state, value)=> state.avgmonthlybudgets = value,

    STORE_TOTAL_MONTHLYAMOUNT: (state, data)=> state.totalmonthlybudgets = data,
    SET_IS_FETCHING_TOTALMONTHLYANOUNT: (state, value)=> state.totalmonthlybudgets = value,

   }

export default {
    namespaced: true,
    state,
    mutations,
};
