<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { Chart, registerables } from "chart.js";
import api from "@/services/api";
import { apiRoutes } from "@/services/apiRoutes";
import { useRouter } from 'vue-router'
import { format, startOfYear, endOfYear, startOfMonth, endOfMonth, subMonths, subYears } from 'date-fns';
Chart.register(...registerables);
const store = useStore();
const router = useRouter()
const selectedWeek = ref("This week");
const currentYear = ref(new Date().getFullYear());
const selectedCompaniesFilter = ref(currentYear.value.toString());
const selectedCategoriesFilter = ref("2024");
const activeTab = ref("Employment status");
const signupTimeFilter = ref("This Week");
const signupTimeFilterOptions = ["This Week", "Yearly"];
const yearOptions = Array.from({length: 5}, (_, i) => (new Date().getFullYear() - i).toString());
const totalCustomers = ref("Loading...");
const totalActiveCustomers = ref("Loading...");
const totalDeactivatedCustomers = ref("Loading...");
const totalPartners = ref("Loading");
const totalInfluencers = ref("Loading");
const recentSignups = ref([]);
const selectedFilter = ref(`year_${new Date().getFullYear()}`);
const selectedYear = ref(new Date().getFullYear().toString());
const selectedMonth = ref(String(new Date().getMonth() + 1).padStart(2, '0'));
const customStartDate = ref(null);
const customEndDate = ref(null);
const recentCompanies = ref([]);
const totalCompanies = ref(0);
const availableYears = ref([
  new Date().getFullYear(),
  new Date().getFullYear() - 1
]);
const navigateToMonthlyBudget = () => {
  console.log('Navigate to monthly budget called')
  router.push('/analytics/monthly-budget')
}
const changeFinancialYear = (year) => {
  selectedYear.value = year;
  fetchFinancialOverview(year);
};
const filterOptions = [
  { label: 'This Week', value: 'week' },
  { label: 'This Month', value: 'month' },
  { label: 'Last 3 Months', value: 'last_3_months' },
  { label: 'Last 6 Months', value: 'last_6_months' },
  { label: `Year ${new Date().getFullYear()}`, value: `year_${new Date().getFullYear()}` },
  // { label: `${new Date().getFullYear()}`, value: 'year_to_date' },
  { label: `Year ${new Date().getFullYear() - 1}`, value: `year_${new Date().getFullYear() - 1}` },
  { label: `Year ${new Date().getFullYear() - 2}`, value: `year_${new Date().getFullYear() - 2}` },
  { label: `Year ${new Date().getFullYear() - 3}`, value: `year_${new Date().getFullYear() - 3}` },
  { label: `Year ${new Date().getFullYear() - 4}`, value: `year_${new Date().getFullYear() - 4}` },
  { label: 'Custom', value: 'custom' }
];
const months = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];
const showYearMonthFilter = computed(() =>
    selectedFilter.value.startsWith('year_')
);

const showCustomDateFilter = computed(() =>
    selectedFilter.value === 'custom'
);
const incomeStatistics = ref({
  total_income: "Loading...",
  average_income: "Loading...",
  total_users: "Loading..."
});

const savingsStatistics = ref({
  total_savings: "Loading...",
  average_savings: "Loading...",
  total_users: "Loading..."
});
const expensesStatistics = ref({
  total_expenses: "Loading...",
  average_expenses: "Loading...",
  total_users: "Loading..."
});

const loansStatistics = ref({
  total_loans: "Ksh 0M",
  total_paid: "Ksh 0M",
  average_loans: "Ksh 0",
  average_paid: "Ksh 0",
  total_users: "Loading...",
  average_interest_rate: "0%",
  min_interest_rate: "0%",
  max_interest_rate: "0%",
  repayment_percentage: "0%",
  average_loan_term: "N/A"
});

const investmentsStatistics = ref({
  total_investments: "Ksh 0M",
  total_deposited: "Ksh 0M",
  average_investments: "Ksh 0",
  average_deposited: "Ksh 0",
  total_users: "Loading...",
  average_interest_rate: "0%",
  min_interest_rate: "0%",
  max_interest_rate: "0%",
  investment_completion_percentage: "0%"
});
const assetsStatistics = ref({
  total_asset_value: "Loading...",
  average_current_value_per_user: "Loading...",
  total_users: "Loading...",
  total_purchase_value: "Loading...",
  average_purchase_value_per_user: "Loading...",
  average_valuation_change: "Loading...",
});

const monthlyBudgetStatistics = ref({
  total_income_budget: "Loading...",
  total_expense_budget: "Loading...",
  average_income_budget: "Loading...",
  average_expense_budget: "Loading...",
  total_users: "Loading..."
});

const projectBudgetStatistics = ref({
  total_income_budget: "Loading...",
  total_expense_budget: "Loading...",
  average_income_budget: "Loading...",
  average_expense_budget: "Loading...",
  total_users: "Loading..."
});
const signupData = ref({
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  values: new Array(12).fill(0),
  total: 0
});

const customerDemographics = ref({
  '18-25': 0,
  '26-35': 0,
  '36-45': 0,
  '46-59': 0,
  '60+': 0
});

const userFirstName = computed(() => {
  const user = store.state.auth?.user;
  if (user && typeof user.name === 'string') {
    const nameParts = user.name.split(" ");
    return nameParts.length > 0 ? nameParts[0] : "";
  }
  return "";
});
const financialData = ref({
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
  income: new Array(12).fill(0),
  expenses: new Array(12).fill(0),
  savings: new Array(12).fill(0)
});
let signupsChart = null;
let demographicsChart = null;
let incomeExpensesChart = null;

const fetchFinancialOverview = (year = new Date().getFullYear()) => {
  api.get(`${apiRoutes.ADMIN_FETCH_MONTHLY_FINANCIAL_TRACKING}`, {
    params: { year }
  })
      .then(response => {
        if (response.data && response.data.status === 'success') {
          const data = response.data.data;

          financialData.value.income = new Array(12).fill(0);
          financialData.value.expenses = new Array(12).fill(0);
          financialData.value.savings = new Array(12).fill(0);

          data.forEach(item => {
            const monthIndex = item.month - 1;
            financialData.value.income[monthIndex] = Number(item.income) || 0;
            financialData.value.expenses[monthIndex] = Number(item.expenses) || 0;
            financialData.value.savings[monthIndex] = Number(item.savings) || 0;
          });

          renderChart();
        } else {
          renderChart();
        }
      })
      .catch(error => {
        renderChart();
      });
};
const renderChart = () => {
  const incomeExpensesCtx = document.getElementById("incomeExpensesChart");
  if (incomeExpensesChart) {
    incomeExpensesChart.destroy();
  }

  incomeExpensesChart = new Chart(incomeExpensesCtx, {
    type: "bar",
    data: {
      labels: financialData.value.labels,
      datasets: [
        {
          label: "Income",
          data: financialData.value.income,
          backgroundColor: "#FC9D33"
        },
        {
          label: "Expense",
          data: financialData.value.expenses,
          backgroundColor: "#9B6BE9"
        },
        {
          label: "Savings",
          data: financialData.value.savings,
          backgroundColor: "#D1BBF5"
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          stacked: false,
          grid: {
            display: false
          }
        },
        y: {
          display: true,
          beginAtZero: true,
          stacked: false,
          grid: {
            display: false
          },
          ticks: {
            callback: function(value) {
              return value === 0 ? '0' : value;
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true
        }
      }
    }
  });
};
function fetchAllStatistics() {
  const filterParams = getFilterParams(selectedFilter.value);

  fetchIncomeStatistics(filterParams);
  fetchSavingsStatistics(filterParams);
  fetchExpensesStatistics(filterParams);
  fetchLoansStatistics(filterParams);
  fetchInvestmentsStatistics(filterParams);
  fetchAssetsStatistics(filterParams);
  fetchMonthlyBudgetStatistics(filterParams);
  fetchProjectBudgetStatistics(filterParams);

}
defineExpose({
  financialData,
  fetchFinancialOverview,
  renderChart
});
const initCharts = () => {
  // Sign ups chart
  const signupsCtx = document.getElementById("signupsChart");
  signupsChart = new Chart(signupsCtx, {
    type: "line",
    data: {
      labels: signupData.value.labels,
      datasets: [{
        label: "Sign ups",
        data: signupData.value.values,
        backgroundColor: "rgba(124, 58, 237, 0.1)",
        borderColor: "#7c3aed",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false
          },
          suggestedMax: Math.max(...signupData.value.values || [0]) * 1.2
        },
        x: {
          grid: {
            display: false
          }
        }
      }
    }
  });

  // Demographics pie chart
  const demographicsCtx = document.getElementById("demographicsChart");
  demographicsChart = new Chart(demographicsCtx, {
    type: "pie",
    data: {
      labels: Object.keys(customerDemographics.value),
      datasets: [{
        data: Object.values(customerDemographics.value),
        backgroundColor: ["#9B6BE9", "#FE0C75", "#D1BBF5", "#10b981", "#f97316"]
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        }
      }
    }
  });

  // Income, Expenses & Savings Chart
  const incomeExpensesCtx = document.getElementById("incomeExpensesChart");
  const financialDataRef = ref({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
    income: [],
    expenses: [],
    savings: []
  });

  const fetchFinancialOverview = async (year = new Date().getFullYear()) => {
    try {
      const response = await axios.get('/api/monthly-financial-overview', {
        params: { year }
      });

      if (response.data.status === 'success') {
        const data = response.data.data;

        financialDataRef.value.income = data.map(item => item.income);
        financialDataRef.value.expenses = data.map(item => item.expenses);
        financialDataRef.value.savings = data.map(item => item.savings);

        // Render or update chart
        renderIncomeExpensesChart();
      }
    } catch (error) {
      financialDataRef.value.income = [35, 40, 38, 42, 36, 39, 41, 37, 43, 40, 38, 41];
      financialDataRef.value.expenses = [28, 32, 30, 33, 29, 31, 34, 30, 35, 32, 30, 33];
      financialDataRef.value.savings = [7, 8, 8, 9, 7, 8, 7, 7, 8, 8, 8, 8];

      renderIncomeExpensesChart();
    }
  };

  const renderIncomeExpensesChart = () => {
    if (window.incomeExpensesChart) {
      window.incomeExpensesChart.destroy();
    }

    window.incomeExpensesChart = new Chart(incomeExpensesCtx, {
      type: "bar",
      data: {
        labels: financialDataRef.value.labels,
        datasets: [
          {
            label: "Income",
            data: financialDataRef.value.income,
            backgroundColor: "#FB8500"
          },
          {
            label: "Expense",
            data: financialDataRef.value.expenses,
            backgroundColor: "#9B6BE9"
          },
          {
            label: "Savings",
            data: financialDataRef.value.savings,
            backgroundColor: "#E0D1F8"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: false,
            grid: {
              display: false
            }
          },
          y: {
            stacked: false,
            grid: {
              display: false
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  };

  // Categories Chart
  const categoriesCtx = document.getElementById("categoriesChart");
  new Chart(categoriesCtx, {
    type: "bar",
    data: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Savings",
          data: [12, 14, 13, 15, 13, 14, 15, 14, 16, 15, 14, 15],
          backgroundColor: "#E0D1F8"
        },
        {
          label: "Expenses",
          data: [30, 35, 32, 37, 33, 36, 38, 34, 39, 36, 35, 37],
          backgroundColor: "#9B6BE9"
        },
        {
          label: "Income",
          data: [25, 28, 26, 30, 27, 29, 31, 28, 32, 30, 28, 30],
          backgroundColor: "#FB8500"
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false
          }
        },
        y: {
          stacked: true,
          grid: {
            display: false
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    }
  });
};
function updateSignupsChart() {
  if (signupsChart) {
    signupsChart.data.labels = signupData.value.labels;
    signupsChart.data.datasets[0].data = signupData.value.values;
    signupsChart.options.scales.y.suggestedMax =
        Math.max(...signupData.value.values || [0]) * 1.2;
    signupsChart.update();
  }
}

function processYearlySignupData(data, selectedYear) {
  const signupData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    values: new Array(12).fill(0),
    total: 0
  };
  if (!data || !data.success || !data.data || !data.data.monthly) {
    return signupData;
  }

  data.data.monthly.forEach((monthData, index) => {
    signupData.values[index] = monthData.count || 0;
  });

  signupData.total = signupData.values.reduce((a, b) => a + b, 0);

  return signupData;
}

function fetchCustomerSignups() {
  const currentYear = new Date().getFullYear();

  const params = {
    filter: null,
    start_date: null,
    end_date: null,
    year: null
  };

  switch (signupTimeFilter.value) {
    case "This Week":
      const endDate = new Date();
      const startDate = new Date(endDate);
      startDate.setDate(endDate.getDate() - 6);  // Ensure full 7 days

      params.filter = 'week';
      params.start_date = format(startDate, 'yyyy-MM-dd');
      params.end_date = format(endDate, 'yyyy-MM-dd');
      break;

    case "Yearly":
      params.filter = 'year';
      params.year = selectedCompaniesFilter.value || currentYear.toString();
      break;

    default:
      return;
  }

  Object.keys(params).forEach(key => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  setDefaultSignupData();

  api.get(apiRoutes.ADMIN_FETCH_CUSTOMER_SIGNUPS, { params })
      .then(response => {
        if (!response.data || !response.data.success) {
          updateSignupsChart();
          return;
        }

        let processedSignupData;
        switch (signupTimeFilter.value) {
          case "This Week":
            processedSignupData = processWeeklySignupData(response.data);
            break;
          case "Yearly":
            processedSignupData = processYearlySignupData(
                response.data,
                selectedCompaniesFilter.value || currentYear.toString()
            );
            break;
        }
        signupData.value = processedSignupData;
        updateSignupsChart();
      })
      .catch(error => {
        signupData.value = {
          labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          values: [0, 0, 0, 0, 0, 0, 0],
          total: 0
        };
        updateSignupsChart();
      });
}
function processWeeklySignupData(data) {
  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const signupData = {
    labels: weekDays,
    values: [0, 0, 0, 0, 0, 0, 0],
    total: 0
  };
  if (!data || !data.success || !Array.isArray(data.data)) {
    return signupData;
  }
  data.data.forEach(item => {
    const dayIndex = weekDays.indexOf(item.day);
    if (dayIndex !== -1) {
      signupData.values[dayIndex] = item.count || 0;
      signupData.total += item.count || 0;
    }
  });
  return signupData;
}
function updateSignupChartData() {
  const currentActualYear = new Date().getFullYear();
  const selectedYear = selectedCompaniesFilter.value || currentActualYear.toString();
  const validYear = parseInt(selectedYear) <= currentActualYear
      ? selectedYear
      : currentActualYear.toString();

  const apiParams = {
    filter: null,
    start_date: null,
    end_date: null,
    year: null
  };
  switch (signupTimeFilter.value) {
    case "This Week":
      const endDate = new Date();
      const startDate = new Date(endDate);
      startDate.setDate(endDate.getDate() - 7);

      apiParams.filter = 'week';
      apiParams.start_date = format(startDate, 'yyyy-MM-dd');
      apiParams.end_date = format(endDate, 'yyyy-MM-dd');
      break;

    case "Monthly":
      const monthStart = startOfMonth(new Date());
      const monthEnd = endOfMonth(new Date());

      apiParams.filter = 'month';
      apiParams.start_date = format(monthStart, 'yyyy-MM-dd');
      apiParams.end_date = format(monthEnd, 'yyyy-MM-dd');
      break;

    case "Yearly":
      apiParams.filter = 'year';
      apiParams.year = validYear;
      break;

    default:
      return;
  }
  Object.keys(apiParams).forEach(key => {
    if (apiParams[key] === null) {
      delete apiParams[key];
    }
  });
  api.get(apiRoutes.ADMIN_FETCH_CUSTOMER_SIGNUPS, { params: apiParams })
      .then(response => {
        if (!response.data || !response.data.success) {
          throw new Error('Invalid signup data response');
        }
        const monthLabels = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        switch (signupTimeFilter.value) {
          case "This Week":
            signupData.value = {
              labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
              values: response.data.data.map(item => item.count || 0),
              total: response.data.data.reduce((sum, item) => sum + (item.count || 0), 0)
            };
            break;

          case "Monthly":
            signupData.value = {
              labels: monthLabels,
              values: monthLabels.map(month => {
                const monthData = response.data.data.find(item => item.month === month);
                return monthData ? monthData.count : 0;
              }),
              total: response.data.data.reduce((sum, item) => sum + item.count, 0)
            };
            break;

          case "Yearly":
            signupData.value = {
              labels: monthLabels,
              values: monthLabels.map(month => {
                const monthData = response.data.data.monthly.find(item => item.month === month);
                return monthData ? monthData.count : 0;
              }),
              total: response.data.data.monthly.reduce((sum, item) => sum + item.count, 0)
            };
            break;
        }
        updateSignupsChart();
      })
      .catch(error => {

        const fallbackLabels = signupTimeFilter.value === "This Week"
            ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
            : monthLabels;

        signupData.value = {
          labels: fallbackLabels,
          values: new Array(fallbackLabels.length).fill(0),
          total: 0
        };

        updateSignupsChart();
      });
}
function updateDemographicsChart() {
  if (demographicsChart) {
    demographicsChart.data.labels = Object.keys(customerDemographics.value);
    demographicsChart.data.datasets[0].data = Object.values(customerDemographics.value);
    demographicsChart.update();
  }
}


watch(selectedCompaniesFilter, () => {
  if (signupTimeFilter.value === "Yearly") {
    fetchCustomerSignups();
  }
});
watch([signupTimeFilter, selectedCompaniesFilter], () => {
  updateSignupChartData();
});
function setDefaultSignupData() {
  if (signupTimeFilter.value === "This Week") {
    signupData.value = {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      values: [0, 0, 0, 0, 0, 0, 0],
      total: 0
    };
  } else if (signupTimeFilter.value === "Yearly") {
    const monthsOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    signupData.value = {
      labels: monthsOrder,
      values: new Array(12).fill(0),
      total: 0
    };
  }
  updateSignupsChart();
}
watch(signupTimeFilter, () => {
  fetchCustomerSignups();
});
watch([selectedYear, selectedMonth], () => {
  if (selectedFilter.value.startsWith('year_')) {
    fetchAllStatistics();
  }
});

watch([customStartDate, customEndDate], () => {
  if (selectedFilter.value === 'custom') {
    fetchAllStatistics();
  }
});
function getFilterParams(filterType) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  try {
    let startDate, endDate;
    if (selectedFilter.value.startsWith('year_')) {
      const selectedYearValue = parseInt(selectedFilter.value.split('_')[1] || currentYear.toString());
      const selectedMonthValue = parseInt(selectedMonth.value || (currentMonth + 1).toString()) - 1;
      const safeYear = Math.min(Math.max(selectedYearValue, 2000), currentYear);
      const safeMonth = Math.min(Math.max(selectedMonthValue, 0), 11);

      startDate = new Date(safeYear, safeMonth, 1);

      if (safeYear < currentYear || (safeYear === currentYear && safeMonth < currentMonth)) {
        endDate = new Date(safeYear, safeMonth + 1, 0);
      } else if (safeYear === currentYear && safeMonth === currentMonth) {
        endDate = now;
      } else {
        endDate = new Date(safeYear, safeMonth + 1, 0);
      }
    } else {
      switch(selectedFilter.value) {
        case 'week':
          startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
          endDate = now;
          break;
        case 'month':
          startDate = new Date(now.getFullYear(), now.getMonth(), 1);
          endDate = now;
          break;
        case 'last_3_months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate());
          endDate = now;
          break;
        case 'last_6_months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
          endDate = now;
          break;
        case 'year_to_date':
          startDate = new Date(now.getFullYear(), 0, 1);
          endDate = now;
          break;
        case 'custom':
          if (customStartDate.value && customEndDate.value) {
            startDate = new Date(customStartDate.value);
            endDate = new Date(customEndDate.value);
            if (startDate > endDate) {
              [startDate, endDate] = [endDate, startDate];
            }
          } else {
            throw new Error('Custom date range not fully specified');
          }
          break;
        default:
          startDate = new Date(now.getFullYear(), 0, 1);
          endDate = now;
      }
    }
    if (!startDate || !endDate || isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      startDate = new Date(currentYear, 0, 1);
      endDate = now;
    }

    const result = {
      start_date: format(startDate, 'yyyy-MM-dd'),
      end_date: format(endDate, 'yyyy-MM-dd')
    };

    return result;

  } catch (error) {
    const fallbackStart = new Date(currentYear, 0, 1);
    const fallbackEnd = now;

    return {
      start_date: format(fallbackStart, 'yyyy-MM-dd'),
      end_date: format(fallbackEnd, 'yyyy-MM-dd')
    };
  }
}

function formatCurrency(value, divisor = 1000000, unit = 'M') {
  if (!value) return `Ksh 0${unit}`;
  const numValue = parseFloat(value);
  if (numValue >= 1000000000 && numValue < 1000000000000) {
    return `Ksh ${(numValue / 1000000000).toFixed(1)}B`;
  } else if (numValue >= 1000000 && numValue < 1000000000) {
    return `Ksh ${(numValue / 1000000).toFixed(1)}M`;
  } else if (numValue >= 10000 && numValue < 1000000) {
    return `Ksh ${(numValue / 1000).toFixed(1)}K`;
  } else if (numValue >= 1 && numValue < 10000) {
    return `Ksh ${numValue.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  } else {
    return `Ksh ${numValue.toFixed(2)}`;
  }
}

function fetchIncomeStatistics(filterParams = {}) {
  api.get(`${apiRoutes.ADMIN_FETCH_INCOME_STATISTICS}`, { params: filterParams })
      .then(response => {
        if (response.data && response.data.status === 'success') {
          const data = response.data.data.income_summary;

          incomeStatistics.value = {
            total_income: formatCurrency(data.total_income),
            average_income: formatCurrency(data.average_income),
            total_users: data.total_users || 0
          };
        } else {
          incomeStatistics.value = {
            total_income: "Ksh 0M",
            average_income: "Ksh 0",
            total_users: "Error"
          };
        }
      })
      .catch(error => {
        incomeStatistics.value = {
          total_income: "Ksh 0M",
          average_income: "Ksh 0",
          total_users: "Error"
        };
      });
}

function fetchSavingsStatistics(filterParams = {}) {
  api.get(`${apiRoutes.ADMIN_FETCH_SAVINGS_STATISTICS}`, { params: filterParams })
      .then(response => {
        if (response.data && response.data.status === 'success') {
          const data = response.data.data;
          savingsStatistics.value = {
            total_savings: formatCurrency(data.total_savings),
            average_savings: formatCurrency(data.average_savings, 1, ''),
            total_users: data.total_users || 0
          };
        } else {
          savingsStatistics.value = {
            total_savings: "Ksh 0M",
            average_savings: "Ksh 0",
            total_users: "Error"
          };
        }
      })
      .catch(error => {
        savingsStatistics.value = {
          total_savings: "Ksh 0M",
          average_savings: "Ksh 0",
          total_users: "Error"
        };
      });
}

function fetchExpensesStatistics(filterParams = {}) {
  api.get(`${apiRoutes.ADMIN_FETCH_EXPENSES_STATISTICS}`, { params: filterParams })
      .then(response => {
        if (response.data && response.data.status === 'success') {
          const data = response.data.data.expense_summary;
          expensesStatistics.value = {
            total_expenses: formatCurrency(data.total_expenses),
            average_expenses: formatCurrency(data.average_expense),
            total_users: data.total_users || 0
          };
        } else {
          expensesStatistics.value = {
            total_expenses: "Ksh 0M",
            average_expenses: "Ksh 0",
            total_users: "Error"
          };
        }
      })
      .catch(error => {
        expensesStatistics.value = {
          total_expenses: "Ksh 0M",
          average_expenses: "Ksh 0",
          total_users: "Error"
        };
      });
}

function fetchLoansStatistics(filterParams = {}) {
  api.get(`${apiRoutes.ADMIN_FETCH_LOANS_STATISTICS}`, { params: filterParams })
      .then(response => {
        if (response.data && response.data.status === 'success') {
          const data = response.data.data.loans_summary;
          const interestRates = response.data.data.interest_rate_details;
          const performanceMetrics = response.data.data.performance_metrics;

          loansStatistics.value = {
            total_loans: formatCurrency(data.total_principal_amount),
            total_paid: formatCurrency(data.total_amount_paid),
            average_loans: formatCurrency(data.average_principal_amount, 1, ''),
            average_paid: formatCurrency(data.average_amount_paid, 1, ''),
            total_users: data.total_users || 0,
            average_interest_rate: `${interestRates.avg_interest_rate.toFixed(2)}%`,
            min_interest_rate: `${interestRates.min_interest_rate}%`,
            max_interest_rate: `${interestRates.max_interest_rate}%`,
            repayment_percentage: `${performanceMetrics.total_repayment_percentage.toFixed(2)}%`,
            average_loan_term: performanceMetrics.average_loan_term
          };
        } else {
          loansStatistics.value = {
            total_loans: "Ksh 0M",
            total_paid: "Ksh 0M",
            average_loans: "Ksh 0",
            average_paid: "Ksh 0",
            total_users: "Error",
            average_interest_rate: "0%",
            min_interest_rate: "0%",
            max_interest_rate: "0%",
            repayment_percentage: "0%",
            average_loan_term: "N/A"
          };
        }
      })
      .catch(error => {
        loansStatistics.value = {
          total_loans: "Ksh 0M",
          total_paid: "Ksh 0M",
          average_loans: "Ksh 0",
          average_paid: "Ksh 0",
          total_users: "Error",
          average_interest_rate: "0%",
          min_interest_rate: "0%",
          max_interest_rate: "0%",
          repayment_percentage: "0%",
          average_loan_term: "N/A"
        };
      });
}

function fetchInvestmentsStatistics(filterParams = {}) {
  api.get(`${apiRoutes.ADMIN_FETCH_INVESTMENTS_STATISTICS}`, { params: filterParams })
      .then(response => {
        if (response.data && response.data.status === 'success') {
          const data = response.data.data.investments_summary;
          const interestRates = response.data.data.interest_rate_details;

          investmentsStatistics.value = {
            total_investments: formatCurrency(data.total_investment_goal),
            total_deposited: formatCurrency(data.total_amount_deposited),
            average_investments: formatCurrency(data.average_investment_goal, 1, ''),
            average_deposited: formatCurrency(data.average_amount_deposited, 1, ''),
            total_users: data.total_users || 0,
            average_interest_rate: `${interestRates.avg_interest_rate.toFixed(2)}%`,
            min_interest_rate: `${interestRates.min_interest_rate}%`,
            max_interest_rate: `${interestRates.max_interest_rate}%`,
            investment_completion_percentage: `${data.investment_completion_percentage.toFixed(2)}%`
          };
        } else {
          investmentsStatistics.value = {
            total_investments: "Ksh 0M",
            total_deposited: "Ksh 0M",
            average_investments: "Ksh 0",
            average_deposited: "Ksh 0",
            total_users: "Error",
            average_interest_rate: "0%",
            min_interest_rate: "0%",
            max_interest_rate: "0%",
            investment_completion_percentage: "0%"
          };
        }
      })
      .catch(error => {
        investmentsStatistics.value = {
          total_investments: "Ksh 0M",
          total_deposited: "Ksh 0M",
          average_investments: "Ksh 0",
          average_deposited: "Ksh 0",
          total_users: "Error",
          average_interest_rate: "0%",
          min_interest_rate: "0%",
          max_interest_rate: "0%",
          investment_completion_percentage: "0%"
        };
      });
}

function fetchAssetsStatistics(filterParams = {}) {
  api.get(`${apiRoutes.ADMIN_FETCH_ASSETS_STATISTICS}`, { params: filterParams })
      .then(response => {
        if (response.data && response.data.status === 'success') {
          const data = response.data.data;

          assetsStatistics.value = {
            total_asset_value: formatCurrency(parseFloat(data.total_asset_value) || 0),
            average_current_value_per_user: formatCurrency(parseFloat(data.average_current_value_per_user) || 0, 1, ''),
            total_users: data.total_users || 0,
            total_purchase_value: formatCurrency(parseFloat(data.total_purchase_value) || 0),
            average_purchase_value_per_user: formatCurrency(parseFloat(data.average_purchase_value_per_user) || 0, 1, ''),
            average_valuation_change: `${parseFloat(data.average_valuation_change || 0).toFixed(2)}%`
          };
        } else {
          assetsStatistics.value = {
            total_asset_value: "Ksh 0M",
            average_current_value_per_user: "Ksh 0",
            total_users: "Error",
            total_purchase_value: "Ksh 0M",
            average_purchase_value_per_user: "Ksh 0",
            average_valuation_change: "0%"
          };
        }
      })
      .catch(error => {
        assetsStatistics.value = {
          total_asset_value: "Ksh 0M",
          average_current_value_per_user: "Ksh 0",
          total_users: "Error",
          total_purchase_value: "Ksh 0M",
          average_purchase_value_per_user: "Ksh 0",
          average_valuation_change: "0%"
        };
      });
}

function fetchMonthlyBudgetStatistics(filterParams = {}) {
  api.get(`${apiRoutes.ADMIN_FETCH_MONTHLY_BUDGET_STATISTICS}`, { params: filterParams })
      .then(response => {
        if (response.data && response.data.status === 'success') {
          const data = response.data.data;
          monthlyBudgetStatistics.value = {
            total_income_budget: formatCurrency(data.income.total_income_budget_amount),
            total_expense_budget: formatCurrency(data.expense.total_expense_budget_amount),
            average_income_budget: formatCurrency(data.income.average_income_budget_amount, 1, ''),
            average_expense_budget: formatCurrency(data.expense.average_expense_budget_amount, 1, ''),
            total_users: data.total_users || 0
          };
        } else {
          monthlyBudgetStatistics.value = {
            total_income_budget: "Ksh 0M",
            total_expense_budget: "Ksh 0M",
            average_income_budget: "Ksh 0",
            average_expense_budget: "Ksh 0",
            total_users: "Error"
          };
        }
      })
      .catch(error => {
        monthlyBudgetStatistics.value = {
          total_income_budget: "Ksh 0M",
          total_expense_budget: "Ksh 0M",
          average_income_budget: "Ksh 0",
          average_expense_budget: "Ksh 0",
          total_users: "Error"
        };
      });
}

function fetchProjectBudgetStatistics(filterParams = {}) {
  api.get(`${apiRoutes.ADMIN_FETCH_PROJECT_BUDGET_STATISTICS}`, { params: filterParams })
      .then(response => {
        if (response.data && response.data.status === 'success') {
          const data = response.data.data;
          projectBudgetStatistics.value = {
            total_income_budget: formatCurrency(data.income.total_income_budget_amount),
            total_expense_budget: formatCurrency(data.expense.total_expense_budget_amount),
            average_income_budget: formatCurrency(data.income.average_income_budget_amount, 1, ''),
            average_expense_budget: formatCurrency(data.expense.average_expense_budget_amount, 1, ''),
            total_users: data.total_users || 0
          };
        } else {
          projectBudgetStatistics.value = {
            total_income_budget: "Ksh 0M",
            total_expense_budget: "Ksh 0M",
            average_income_budget: "Ksh 0",
            average_expense_budget: "Ksh 0",
            total_users: "Error"
          };
        }
      })
      .catch(error => {
        projectBudgetStatistics.value = {
          total_income_budget: "Ksh 0M",
          total_expense_budget: "Ksh 0M",
          average_income_budget: "Ksh 0",
          average_expense_budget: "Ksh 0",
          total_users: "Error"
        };
      });
}

function fetchCustomerDemographics() {
  api.get(`${apiRoutes.ADMIN_FETCH_CUSTOMER_DEMOGRAPHICS}`)
      .then(response => {
        if (response.data && response.data.status === 'success') {
          customerDemographics.value = response.data.data;
          updateDemographicsChart();
        }
      })
      .catch(error => {
        console.error("Error fetching customer demographics:", error);
      });
}

function fetchCustomers() {
  api.get(`${apiRoutes.ADMIN_FETCH_CUSTOMER_COUNT}`).then(response => {
    if (response.data && response.data.success && response.data.data && response.data.data.count !== undefined) {
      const count = response.data.data.count;
      if (count >= 1000) {
        totalCustomers.value = Math.floor(count / 1000) + 'k';
      } else {
        totalCustomers.value = count.toString();
      }
    } else {
      totalCustomers.value = "Error loading";
    }
  }).catch(error => {
    totalCustomers.value = "Error loading";
  });
}

function fetchActiveCustomers() {
  api.get(`${apiRoutes.ADMIN_FETCH_ACTIVE_CUSTOMERS}`)
      .then(response => {
        if (response.data && response.data.success && response.data.data && response.data.data.count !== undefined) {
          const count = response.data.data.count;
          totalActiveCustomers.value = count >= 1000 ? Math.floor(count / 1000) + 'k' : count.toString();
        } else {
          totalActiveCustomers.value = "Error loading";
        }
      })
      .catch(error => {
        totalActiveCustomers.value = "Error loading";
      });
}

function fetchDeactivatedCustomers() {
  api.get(`${apiRoutes.ADMIN_FETCH_DEACTIVATED_CUSTOMERS}`)
      .then(response => {
        if (response.data && response.data.success && response.data.data && response.data.data.count !== undefined) {
          const count = response.data.data.count;
          totalDeactivatedCustomers.value = count >= 1000 ? Math.floor(count / 1000) + 'k' : count.toString();
        } else {
          totalDeactivatedCustomers.value = "Error loading";
        }
      })
      .catch(error => {
        totalDeactivatedCustomers.value = "Error loading";
      });
}

function fetchPartners() {
  api.get(`${apiRoutes.ADMIN_FETCH_PARTNER_COUNT}`).then(response => {
    if (response.data && response.data.success && response.data.data && response.data.data.count !== undefined) {
      const count = response.data.data.count;
      if (count >= 1000) {
        totalPartners.value = Math.floor(count / 1000) + 'k';
      } else {
        totalPartners.value = count.toString();
      }
    } else {
      totalPartners.value = "Error loading";
    }
  }).catch(error => {
    totalPartners.value = "Error loading";
  });
}

function fetchInfluencersCount() {
  api.get(`${apiRoutes.ADMIN_FETCH_INFLUENCER_COUNT}`).then(response => {
    if (response.data && response.data.success && response.data.data && response.data.data.count !== undefined) {
      const count = response.data.data.count;
      if (count >= 1000) {
        totalInfluencers.value = Math.floor(count / 1000) + 'k';
      } else {
        totalInfluencers.value = count.toString();
      }
    } else {
      totalInfluencers.value = "Error loading";
    }
  }).catch(error => {
    totalInfluencers.value = "Error loading";
  });
}

function fetchRecentSignups() {
  api.get(`${apiRoutes.ADMIN_FETCH_RECENT_SIGNUPS}`)
      .then(response => {
        if (response.data && response.data.signups) {
          recentSignups.value = response.data.signups;
        } else {
          recentSignups.value = [];
        }
      })
      .catch(error => {
        recentSignups.value = [];
      });
}
function fetchRecentCompanies() {
  api.get(`${apiRoutes.ADMIN_FETCH_RECENT_COMPANIES}`)
      .then(response => {
        if (response.data && response.data.status === 'success') {
          recentCompanies.value = response.data.data.recent_companies || [];
          totalCompanies.value = response.data.data.total_companies || 0;
        } else {
          recentCompanies.value = [];
          totalCompanies.value = 0;
        }
      })
      .catch(error => {
        recentCompanies.value = [];
        totalCompanies.value = 0;
      });
}



onMounted(() => {
  // updateSignupChartData();
  setDefaultSignupData();
  initCharts();
  fetchCustomerSignups();
  fetchCustomers();
  fetchPartners();
  fetchInfluencersCount();
  fetchCustomerDemographics();
  fetchActiveCustomers();
  fetchDeactivatedCustomers();
  fetchRecentSignups();
  fetchIncomeStatistics();
  fetchSavingsStatistics();
  fetchExpensesStatistics();
  fetchLoansStatistics();
  fetchInvestmentsStatistics();
  fetchAssetsStatistics();
  fetchMonthlyBudgetStatistics();
  fetchProjectBudgetStatistics();
  fetchRecentCompanies();
  fetchFinancialOverview();
});

</script>

<template>
  <div class="dashboard">
    <div class="stats-cards">
      <div class="stat-card total-customers">
        <div class="icon-wrapper">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M12 12.75c1.63 0 3.07-.49 4.24-1.35C17.4 12.85 18 14.34 18 16v1H6v-1c0-1.66.6-3.15 1.76-4.6 1.17.86 2.61 1.35 4.24 1.35zm0-1.5c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm-7 1.5c1.19 0 2.25-.5 3-1.29-.4-.21-.85-.32-1.33-.32h-3.34c-.48 0-.93.11-1.33.32.75.79 1.81 1.29 3 1.29zm0-1.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm14 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm1.33 1.18c-.48 0-.93.11-1.33.32.75.79 1.81 1.29 3 1.29s2.25-.5 3-1.29c-.4-.21-.85-.32-1.33-.32h-3.34z" fill="currentColor"/>
          </svg>
        </div>
        <div class="stat-info">
          <div class="stat-title">Total customers</div>
          <div class="stat-value">{{ totalCustomers }}</div>
        </div>
      </div>

      <div class="stat-card total-partners">
        <div class="icon-wrapper">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L23 10z" fill="currentColor"/>
          </svg>
        </div>
        <div class="stat-info">
          <div class="stat-title">Total partners</div>
          <div class="stat-value">{{ totalPartners }}</div>
        </div>
      </div>

      <div class="stat-card influencers">
        <div class="icon-wrapper">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z" fill="currentColor"/>
            <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" fill="currentColor"/>
          </svg>
        </div>
        <div class="stat-info">
          <div class="stat-title">Influencers</div>
          <div class="stat-value">{{ totalInfluencers }}</div>
        </div>
      </div>
    </div>

    <div class="dashboard-grid">
      <div class="card signups-card">
        <div class="card-header with-dropdown">
          <div class="header-container">
            <h2 class="font-black">Customer sign ups</h2>
            <div class="total-signups">Total: {{ signupData.total }}</div>
          </div>
          <div class="filter-dropdown">
            <select v-model="signupTimeFilter" class="dropdown-toggle">
              <option v-for="option in signupTimeFilterOptions" :key="option" :value="option">
                {{ option }}
              </option>
            </select>

            <select
                v-if="signupTimeFilter === 'Yearly'"
                v-model="selectedCompaniesFilter"
                class="dropdown-toggle ml-2"
            >
              <option v-for="year in yearOptions" :key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="chart-container">
          <canvas id="signupsChart"></canvas>
        </div>
        <div class="chart-footer">
          <div class="stat">
            <span class="dot active"></span>
            <span>Active customers: {{ totalActiveCustomers }}</span>
          </div>
          <div class="stat">
            <span class="dot inactive"></span>
            <span>Deactivated customers: {{ totalDeactivatedCustomers }}</span>
          </div>
        </div>
      </div>

      <div class="card recent-signups-card">
        <div class="card-header with-dropdown">
          <h2 class="font-black">Recent Sign ups</h2>
        </div>
        <div class="table-container">
          <table class="signups-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Email address</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="signup in recentSignups" :key="signup.email">
              <td>{{ signup.first_name }} {{ signup.last_name }}</td>
              <td>{{ signup.email }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card demographics-card">
        <div class="card-header with-dropdown">
          <h2 class="font-black">Customer demographics</h2>
          <div class="dropdown">
            <button class="dropdown-toggle"></button>
          </div>
        </div>
        <div class="chart-container">
          <canvas id="demographicsChart"></canvas>
          <div class="chart-legend">
            <div v-for="(count, ageGroup) in customerDemographics" :key="ageGroup" class="legend-item">
<!--              <span class="color-box" :style="{-->
<!--                backgroundColor:-->
<!--                  ageGroup === '18-25' ? '#9B6BE9' :-->
<!--                  ageGroup === '26-35' ? '#FE0C75' :-->
<!--                  ageGroup === '36-45' ? '#D1BBF5' :-->
<!--                  ageGroup === '46-59' ? '#10b981' :-->
<!--                  '#f97316'-->
<!--              }"></span>-->
<!--              <span>{{ ageGroup }} Yrs: {{ count }}</span>-->
            </div>
          </div>
        </div>
      </div>

      <div class="card financial-card">
        <div class="card-header with-dropdown">
          <h2 class="font-black">Users Financial Overview</h2>
          <div class="financial-filters">
            <div class="filter-container">
              <select
                  v-model="selectedFilter"
                  @change="fetchAllStatistics"
                  class="filter-select"
              >
                <option
                    v-for="option in filterOptions"
                    :key="option.value"
                    :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
              <svg class="dropdown-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M7 10l5 5 5-5z" fill="currentColor"/>
              </svg>
            </div>

            <template v-if="showYearMonthFilter">
              <div class="filter-container">
                <select
                    v-model="selectedMonth"
                    class="filter-select"
                >
                  <option
                      v-for="month in months"
                      :key="month.value"
                      :value="month.value"
                  >
                    {{ month.label }}
                  </option>
                </select>
                <svg class="dropdown-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M7 10l5 5 5-5z" fill="currentColor"/>
                </svg>
              </div>
            </template>

            <template v-if="showCustomDateFilter">
              <div class="date-filter-container">
                <input
                    type="date"
                    v-model="customStartDate"
                    class="date-input"
                    placeholder="From Date"
                >
                <input
                    type="date"
                    v-model="customEndDate"
                    class="date-input"
                    placeholder="To Date"
                >
              </div>
            </template>
          </div>
        </div>

        <div class="financial-grid">
          <div class="financial-column">

            <div class="financial-item">
              <div class="title">Total Income</div>
              <div class="value">{{ incomeStatistics.total_income }}</div>
              <div class="subtitle">Avg/user: {{ incomeStatistics.average_income }}</div>
            </div>
            <div class="financial-item">
              <div class="title">Total Investment</div>
              <div class="value">Goal: {{ investmentsStatistics.total_investments }}</div>
              <div class="value">Deposited: {{ investmentsStatistics.total_deposited }}</div>
              <div class="subtitle">Avg/user goal: {{ investmentsStatistics.average_investments }}</div>
              <div class="subtitle">Avg/user deposited: {{ investmentsStatistics.average_deposited }}</div>
              <div class="small-text">Avg Interest: {{ investmentsStatistics.average_interest_rate }}</div>
              <div class="small-text">Interest Range: {{ investmentsStatistics.min_interest_rate }} - {{ investmentsStatistics.max_interest_rate }}</div>
              <div class="small-text">Completion: {{ investmentsStatistics.investment_completion_percentage }}</div>
            </div>
            <div class="financial-item">
              <div class="title">Total Savings</div>
              <div class="value">{{ savingsStatistics.total_savings }}</div>
              <div class="subtitle">Avg/user: {{ savingsStatistics.average_savings }}</div>
            </div>

            <div class="financial-item">
              <div class="title">Total Project Budget</div>
              <div class="value">Income: {{ projectBudgetStatistics.total_income_budget }}</div>
              <div class="value">Expense: {{ projectBudgetStatistics.total_expense_budget }}</div>
              <div class="subtitle">Avg income/user: {{ projectBudgetStatistics.average_income_budget }}</div>
              <div class="subtitle">Avg expense/user: {{ projectBudgetStatistics.average_expense_budget }}</div>
            </div>
          </div>

          <div class="financial-column">
            <div class="financial-item">
              <div class="title">Total Expenses</div>
              <div class="value">{{ expensesStatistics.total_expenses }}</div>
              <div class="subtitle">Avg/user: {{ expensesStatistics.average_expenses }}</div>
            </div>
            <div class="financial-item">
              <div class="title">Total Loans</div>
              <div class="value">Borrowed: {{ loansStatistics.total_loans }}</div>
              <div class="value">Paid: {{ loansStatistics.total_paid }}</div>
              <div class="subtitle">Avg/user borrowed: {{ loansStatistics.average_loans }}</div>
              <div class="subtitle">Avg/user Paid: {{ loansStatistics.average_paid }}</div>
              <div class="small-text">Avg Interest: {{ loansStatistics.average_interest_rate }}</div>
              <div class="small-text">Interest Range: {{ loansStatistics.min_interest_rate }} - {{ loansStatistics.max_interest_rate }}</div>
              <div class="small-text">Repayment: {{ loansStatistics.repayment_percentage }}</div>
<!--              <div class="small-text">Avg Loan Term: {{ loansStatistics.average_loan_term }} years</div>-->
            </div>
            <div class="financial-item">
              <div class="title">Total Assets</div>
              <div class="value">Current Value: {{ assetsStatistics.total_asset_value }}</div>
              <div class="value">Purchase Value: {{ assetsStatistics.total_purchase_value }}</div>
              <div class="subtitle">Avg Current Value/user: {{ assetsStatistics.average_current_value_per_user }}</div>
              <div class="subtitle">Avg Purchase Value/user: {{ assetsStatistics.average_purchase_value_per_user }}</div>
              <div class="small-text">Avg Valuation Change: {{ assetsStatistics.average_valuation_change }}</div>
            </div>
            <div class="financial-item">
              <div class="title">Total Monthly Budgets</div>
              <div
                  class="value cursor-pointer hover:underline"
                  @click="navigateToMonthlyBudget"
              >
                Income: {{ monthlyBudgetStatistics.total_income_budget }}
              </div>
              <div
                  class="value cursor-pointer hover:underline"
                  @click="navigateToMonthlyBudget"
              >
                Expense: {{ monthlyBudgetStatistics.total_expense_budget }}
              </div>
              <div class="subtitle">Avg income/user: {{ monthlyBudgetStatistics.average_income_budget }}</div>
              <div class="subtitle">Avg expense/user: {{ monthlyBudgetStatistics.average_expense_budget }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="card income-expenses-card">
        <div class="card-header with-dropdown">
          <h2 class="font-black">Income & Expenses Tracking</h2>
          <div class="filter-container">
            <select
                v-model="selectedYear"
                @change="fetchYearData"
                class="filter-select"
            >
              <option
                  v-for="year in availableYears"
                  :key="year"
                  :value="year"
              >
                {{ year }}
              </option>
            </select>
            <svg class="dropdown-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M7 10l5 5 5-5z" fill="currentColor"/>
            </svg>
          </div>
        </div>
        <div class="chart-legend">
          <div class="legend-item">
            <span class="color-box" style="background-color: #FC9D33;"></span>
            <span>Income</span>
          </div>
          <div class="legend-item">
            <span class="color-box" style="background-color: #9B6BE9;"></span>
            <span>Expense</span>
          </div>
          <div class="legend-item">
            <span class="color-box" style="background-color: #D1BBF5;"></span>
            <span>Savings</span>
          </div>
        </div>
        <div class="chart-container">
          <canvas id="incomeExpensesChart"></canvas>
        </div>
      </div>

      <div class="card categories-card">
        <div class="card-header with-dropdown">
          <h2 class="font-black">Categories</h2>
          <div class="dropdown">
            <button class="dropdown-toggle">{{ selectedCategoriesFilter }}</button>
          </div>
        </div>
        <div class="chart-legend">
          <div class="legend-item">
            <span class="color-box" style="background-color: #FC9D33;"></span>
            <span>Income</span>
          </div>
          <div class="legend-item">
            <span class="color-box" style="background-color: #9B6BE9;"></span>
            <span>Expenses</span>
          </div>
          <div class="legend-item">
            <span class="color-box" style="background-color: #D1BBF5;"></span>
            <span>Savings</span>
          </div>
        </div>
        <div class="chart-container">
          <canvas id="categoriesChart"></canvas>
        </div>
      </div>

      <div class="card companies-card">
        <div class="card-header with-dropdown">
          <h2 class="font-black">Recent Companies <span class="text-sm text-gray-500">(Total: {{ totalCompanies }})</span></h2>
          <div class="dropdown">
            <button class="dropdown-toggle"></button>
          </div>
        </div>
        <div class="table-container">
          <table class="companies-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Category</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="company in recentCompanies" :key="company.business_name">
              <td>{{ company.business_name }}</td>
              <td>{{ company.business_type }}</td>
              <td>{{ company.business_industry.type || 'N/A' }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


    </div>
    <router-view name="analytics-view"></router-view>
  </div>
</template>

<style scoped>
@font-face {
  font-family: 'THICCCBOI';
  src: url('@/assets/fonts/THICCCBOI-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('@/assets/fonts/THICCCBOI-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('@/assets/fonts/THICCCBOI-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('@/assets/fonts/THICCCBOI-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('@/assets/fonts/THICCCBOI-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('@/assets/fonts/THICCCBOI-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('@/assets/fonts/THICCCBOI-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('@/assets/fonts/THICCCBOI-ThicccAF.woff2') format('woff2');
  font-weight: 950;
  font-style: normal;
}

body, h1, h2, h3, h4, h5, h6,
.dashboard, input, button, select, textarea, p, label {
  font-family: 'THICCCBOI', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Utility classes for font weights */
.font-thin { font-weight: 100; }
.font-regular { font-weight: 400; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }
.font-extrabold { font-weight: 800; }
.font-black { font-weight: 900; }
.font-thicccaf { font-weight: 950; }
.financial-filters {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.filter-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.filter-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0.5rem 2rem 0.5rem 1rem;
  font-size: 0.875rem;
  color: #1f2937;
  cursor: pointer;
  min-width: 120px;
}

.dropdown-arrow {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  color: #64748b;
  pointer-events: none;
}
h2{
  font-family: 'THICCCBOI', sans-serif;
  font-weight: 900;
  color: #1e293b;
  line-height: 1.2;
  font-size: 1.2rem;
}
.date-filter-container {
  display: flex;
  gap: 0.5rem;
}

.date-input {
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  color: #1f2937;
  max-width: 150px;
}

.filter-select:focus,
.date-input:focus {
  outline: none;
  border-color: #7c3aed;
  box-shadow: 0 0 0 2px rgba(124, 58, 237, 0.2);
}

@media (max-width: 768px) {
  .financial-filters {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }

  .filter-container,
  .date-filter-container {
    width: 100%;
  }

  .filter-select,
  .date-input {
    width: 100%;
    max-width: none;
  }
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.total-signups {
  font-weight: bold;
  color: #7c3aed;
}
.dashboard {
  padding: 1rem;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.stats-cards {
  display: flex;
  gap: 1.95rem;
  width: 40%;
}

.stat-card {
  flex: 0 1 calc(33.333% - 0.75rem);
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  background-color: white;
  padding: 0.75rem;
  border-radius: 12px;
  gap: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.stats-cards .stat-card {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  background-color: white;
  padding: 0.75rem;
  border-radius: 12px;
  gap: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 100%;
}
.total-customers {
  border: 2px solid #f43f5e;
}

.total-partners {
  border: 2px solid #8b5cf6;
}

.influencers {
  border: 2px solid #f97316;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.total-customers .icon-wrapper {
  color: #f43f5e;
}

.total-partners .icon-wrapper {
  color: #8b5cf6;
}

.influencers .icon-wrapper {
  color: #f97316;
}

.stat-info {
  display: flex;
  flex-direction: column;
}

.stat-title {
  font-size: 0.85rem;
  color: #64748b;
}

.stat-value {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1e293b;
  text-align: right;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.5rem;
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
}

.signups-card {
  grid-column: span 5;
}

.recent-signups-card {
  grid-column: span 4;
  margin-top: -7rem;
}

.demographics-card {
  grid-column: span 3;
  margin-top: -7rem;
}

.financial-card {
  grid-column: span 7;
  display: flex;
  flex-direction: column;
}

.income-expenses-card {
  grid-column: span 5;
  height: fit-content;
}

.companies-card .table-container {
  height: fit-content;
  overflow-y: auto;

}
.companies-card {
  grid-column: span 5;
  display: flex;
  margin-top: -27rem;
  flex-direction: column;

}

.categories-card {
  grid-column: span 7;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.card-header h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.with-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-header.with-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.dropdown {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}


.dropdown.flex {
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 768px) {
  .dropdown.flex {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }

  .dropdown select,
  .dropdown input[type="date"] {
    width: 100%;
  }
}
.dropdown-toggle {
  background-color: transparent;
  border: none;
  color: #64748b;
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
}

.dropdown-toggle::after {
  content: "▼";
  font-size: 0.625rem;
  margin-left: 0.5rem;
}

.chart-container {
  position: relative;
  height: 250px;
  width: 100%;
}

.chart-footer {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
}

.stat {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #64748b;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.active {
  background-color: #7c3aed;
}

.inactive {
  background-color: #94a3b8;
}

.table-container {
  overflow-y: auto;
  max-height: 400px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.75rem 1rem;
  text-align: left;
}

th {
  font-size: 0.875rem;
  font-weight: 500;
  color: #64748b;
  border-bottom: 1px solid #e2e8f0;
}

td {
  font-size: 0.875rem;
  color: #1e293b;
  border-bottom: 1px solid #f1f5f9;
}

.chart-legend {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.legend-item {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #64748b;
}

.color-box {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 0.5rem;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.financial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  width: 100%;
}

.financial-column {
  display: grid;
  grid-template-rows: repeat(4, auto);
  gap: 1.5rem;
}
.financial-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 120px;
  max-height: 200px;
  flex: 1;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 1rem;
  text-align: left;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.financial-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f3f4f6;
}

.financial-item .title {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 0.5rem;
  align-self: flex-start;
}

.financial-item .value {
  font-size: 16px;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 0.25rem;
  align-self: flex-start;
}

.financial-item .subtitle {
  font-size: 13px;
  font-weight: 600;
  color: #94a3b8;
  align-self: flex-start;
}
.financial-item .small-text {
  font-size: 12px;
  color: #94a3b8;
  align-self: flex-start;
  font-weight: 500;
}
.companies-card .table-container {
  height: auto;
  overflow-y: visible;
}

.companies-card table {
  width: 100%;
  table-layout: fixed;
}

.companies-card thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.companies-card tbody tr:last-child td {
  border-bottom: none;
}

.companies-card th:nth-child(1) { width: 40%; }
.companies-card th:nth-child(2) { width: 30%; }
.companies-card th:nth-child(3) { width: 30%; }

@media (max-width: 1200px) {
  .financial-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .financial-grid {
    grid-template-columns: 1fr;
  }
}

.filter-dropdown {
  display: flex;
  align-items: center;
}

.filter-dropdown .ml-2 {
  margin-left: 0.5rem;
}

select.dropdown-toggle {
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #1f2937;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20' stroke-width='1.5' stroke='currentColor' class='w-5 h-5'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

select.dropdown-toggle:focus {
  outline: none;
  border-color: #7c3aed;
  box-shadow: 0 0 0 2px rgba(124, 58, 237, 0.2);
}
</style>